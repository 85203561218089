<template>
  <div class="classify-block">
    <div class="breadcrumb">
      <span>{{mname}}</span>
      &gt;
      <span>{{name}}</span>
    </div>
    <div class="content">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="标题：">
          <el-input v-model="form.title" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-cascader :options="options" :show-all-levels="true" :props="{ checkStrictly: false }" clearable
            ref="cascader" leafOnly="false" v-model="form.type">
          </el-cascader>
        </el-form-item>
        <el-form-item label="内容：">
          <el-input type="textarea" rows="7" maxlength="500" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {
    post,
    postextra,
    get
  } from '@/utils/request';
  import {
    isLogin
  } from '../../utils/public';
  export default {
    data() {
      return {
        content: "",
        id: 0,
        mid: 0,
        name: "",
        mname: "",
        form: {
          title: "",
          type: "",
          content: "",
        },
        options: [],
      };
    },
    created() {
      this.$nextTick(() => {
        get('f/api/articleType/selectBugType').then((res) => {
          this.options = res.data ? res.data.data : "";
        })
      });
    },
    methods: {
      onSubmit() {
        if (this.form.title == null || this.form.title == "") {
          this.$notify({
            title: '请填写标题',
            type: 'error',
            duration: 2500,
          });
          return;
        }
        if (this.form.type == null || this.form.type == "") {
          this.$notify({
            title: '请选择类型',
            type: 'error',
            duration: 2500,
          });
          return;
        }
        if (this.form.content == null || this.form.content == "") {
          this.$notify({
            title: '请填写内容',
            type: 'error',
            duration: 2500,
          });
          return;
        }
        postextra('api/articleType/insertBug', {
          title: this.form.title,
          type: this.form.type[0],
          content: this.form.content,
        }).then((x) => {
          if (x.code == 0) {
            this.$notify({
              title: '添加成功',
              type: 'success',
              duration: 2500,
            });
            this.$router.tp({
              name: "helpCenter",
              params: {
                id: this.id,
              }
            });
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .classify-block {
    .breadcrumb {
      background-color: #eaeaea;
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
    }

    background-color: #fff;
    width: 100%;

    .content {
      padding-top: 30px;
      padding-bottom: 30px;
      display: block;
      position: relative;
      min-height: 600px;
      left: 40px;
      width: calc(100% - 90px);
    }
  }
</style>
