<template>
  <div class="classify-block">
    <!--
         <div class="classify">
        <h3 class="h3-title">常见问题分类</h3>
      <ul class="classify-ul">
        <li :class="mid == item.id ? classifyUlLi:classifyUlLiN" v-for="(item, index) in list" :key="index">
          <span class="classify-ul-li-span" @click="ulNone($event)">{{item.articleTypeName}}</span>
          <ul class="classify-ul-li-ul">
            <li v-for="(item2, index2) in item.children" :key="index2" :class="item2.id == id ?active:''"
              @click="handleMenuClick(item2.id,$event)">
              <span>{{item2.articleTypeName}}</span></li>
          </ul>
        </li>
      </ul>  
      </div>
      -->

    <div class="left">
      <div class="menu" v-for="(item, index) in list" :key="index">
        <span class="spen">{{ item.articleTypeName }}</span>
        <ul class="ul">
          <li
            v-for="(item2, index2) in item.children"
            :class="item2.id == id ? li_active : ''"
            :key="index2"
            @click="handleMenuClick(item2.id, $event)"
            tag="li"
          >
            {{ item2.articleTypeName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      list: [],
      id: 0,
      active: 'active',
      li_active: 'li_active',
      classifyUlLiN: 'classify-ul-li ul-none',
      classifyUlLi: 'classify-ul-li',
      mid: 0,
      articleBug: 0,
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.$parent.id != null && this.$parent.id != '') {
        this.id = this.$parent.id;
      }
      get('f/api/articleType/shopArticleTypeVoMenu').then((res) => {
        this.list = res.data ? res.data.data : [];
        if (this.id == '') {
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].children) {
              for (var j = 0; j < this.list[i].children.length; j++) {
                if (this.list[i].children[j]) {
                  this.$parent.id = this.id = this.list[i].children[j].id;
                  this.$parent.handleClick();
                  return;
                }
              }
            }
          }
        }
      });
    });
  },
  methods: {
    ulNone(obj) {
      var className = obj.currentTarget.parentElement.className;
      if (className.indexOf('ul-none') >= 0) {
        obj.currentTarget.parentElement.className = 'classify-ul-li';
      } else {
        obj.currentTarget.parentElement.className = 'classify-ul-li ul-none';
      }
    },
    handleMenuClick(id, obj) {
      this.$router.tp({
        name: 'helpCenter',
        params: {
          id: id,
        },
      });
      //this.$parent.id = this.id = id;
      //this.$parent.handleClick();
    },
  },
};
</script>
<style lang="scss" scoped>
.classify {
  border-top: 1px solid #ededed;
  padding-left: 0px;

  .h3-title {
    margin: 0px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    height: 40px;
    background-color: #7dd589;
    font-family: 'microsoft yahei';
    font-weight: normal;
  }

  .classify-ul {
    list-style: none;
    width: 100%;
    padding: 0px;
    margin-top: 1px;
    margin-bottom: 1px;

    .classify-ul-li {
      height: auto;
      line-height: 40px;
      background-color: #eaeaea;
      border-bottom: 1px solid #fff;
      font-size: 14px;

      .classify-ul-li-span {
        padding-left: 45px;
        display: block;
        position: relative;
      }

      .classify-ul-li-span::after {
        position: absolute;
        right: 15px;
        top: 15px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-bottom: 8px solid #999;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }

      .classify-ul-li-ul {
        display: block;
        background-color: #fff;
        list-style: none;
        padding-left: 0px;

        li {
          height: 30px;
          line-height: 30px;
          border-bottom: 1px solid #faf8f9;
          cursor: pointer;
          span {
            padding-left: 45px;
          }
        }

        .active {
          color: rgb(125, 213, 137);
        }
      }
    }
  }

  .ul-none {
    .classify-ul-li-ul {
      display: none !important;
    }

    .classify-ul-li-span::after {
      position: absolute;
      right: 15px;
      top: 15px;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 8px solid rgba(0, 0, 0, 0) !important;
      border-top-color: #999 !important;
    }
  }
}

li {
  list-style: none;
}
.left {
  width: 155px;
  background: #fff;
  .menu {
    padding: 36px 0;
    padding-left: 30px;
    border-bottom: 1px solid #ededed;
    .spen {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
    .ul {
      font-size: 15px;
      color: #333;
      padding: 0;
      li {
        margin-top: 10px;
        list-style: none;
        cursor: pointer;
      }
      .li_active {
        color: #3661fe;
      }
    }
    .bian {
      color: #1a52ff;
    }
  }
}
</style>
