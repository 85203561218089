<template>
  <div :class="id==13?'classify-block13':'classify-block'" >
    <div class="breadcrumb">
      <span>{{mname}}</span>
      &gt;
      <span>{{name}}</span>
    </div>
    <div class="content" v-html="content">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        content: "",
        id: 0,
        mid:0,
        name:"",
        mname:"",
      };
    },
    created() {
      this.$nextTick(() => {
      });
    },
    methods: {
      selectContent() {
      }
    }
  }
</script>
<style lang="scss" scoped>
  .classify-block {
    .breadcrumb {
      background-color: #eaeaea;
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
    }

    background-color: #fff;
    width: 100%;

    .content {
      padding-top: 30px;
      padding-bottom: 30px;
      display: block;
      position: relative;
      left: 40px;
      width: calc(100% - 90px);
      min-height: 600px;
    }
  }
  .classify-block13{
    .breadcrumb {
      background-color: #eaeaea;
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
    }

    background: url(../../assets/home//beijing.png) no-repeat;
    background-size: 100% 100%;

    width: 100%;

    .content {
      padding-top: 30px;
      padding-bottom: 30px;
      display: block;
      position: relative;
      left: 40px;
      width: calc(100% - 90px);
      min-height: 600px;
    }
  }
</style>