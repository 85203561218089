<template>
    <div class="w-1920">
        <header-user></header-user>
        <search></search>
        <div class="help">
            <div class="help-block w-1200">
                <div class="help-block-left">
                    <help-classify ref="classify"></help-classify>
                </div>
                <div class="help-block-right">
                    <helpcontent v-if="articleBug == 0" ref="content"></helpcontent>
                    <helpbug v-if="articleBug == 1" ref="helpbug"></helpbug>
                </div>
            </div>
        </div>
        <div class="help-foot"></div>
        <!-- <baoyou></baoyou> -->
        <foot></foot>
        <cart></cart>
    </div>
</template>
<script>
    import header_user from "@/components/header";
    import baoyou from "@/components/order/baoyou";
    import foot from "@/components/foot";
    import cart from "@/components/cart";
    import search from "./search";
    import help_classify from "./help-classify";
    import helpcontent from "./help-content";
    import helpbug from "./help-bug";
    import {
        post,
        get
    } from '@/utils/request';
    export default {
        components: {
            "header-user": header_user,
            search: search,
            baoyou: baoyou,
            foot: foot,
            cart: cart,
            "help-classify": help_classify,
            helpcontent: helpcontent,
            helpbug: helpbug,
        },
        data() {
            return {
                params: {},
                id: "",
                mid: 0,
                articleBug: '',
            };
        },
        created() {
            if(this.$route.params.id != null && this.$route.params.id != ""){
                this.id = this.$route.params.id;
                this.handleClick();
            }
            
        },
        methods: {
            selectContent() {
                get('f/api/articleType/selectById?id=' + this.id).then((res) => {
                    this.$refs.classify.mid = res.data ? res.data.data.mid : "";
                    this.articleBug = res.data ? res.data.data.articleBug : "";
                    this.$refs.classify.articleBug = this.articleBug;
                    setTimeout(() => {
                        if (this.articleBug == '0') {
                            this.$refs.content.id = this.id;
                            this.$refs.content.content = res.data ? res.data.data.content : "";
                            this.$refs.content.name = res.data ? res.data.data.articleTypeName : "";
                            this.$refs.content.mname = res.data ? res.data.data.parentName : "";
                        } else {
                            this.$refs.helpbug.id = this.id;
                            this.$refs.helpbug.name = res.data ? res.data.data.articleTypeName : "";
                            this.$refs.helpbug.mname = res.data ? res.data.data.parentName : "";
                        }

                    }, 10)
                });
            },
            handleClick() {
                this.selectContent();
            },
        }

    };
</script>
<style lang="scss" scoped>
    .help {
        background: #f5f5f5;

        .help-block {
            width: 1237px;
            padding-top: 20px;
            margin: auto;
            display: flex;
            flex-direction: row;
            justify-self: start;

            .help-block-left {
                width: 184px;
            }

            .help-block-right {
                margin-left: 20px;
                width: calc(100% - 204px);
            }
        }

    }

    .help-foot {
        display: block;
        height: 20px;
        background-color: whitesmoke;
    }
</style>